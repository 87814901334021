import React from 'react';
import Layout from '../../../components/layout';
import RegistrationFrom from '../../../components/registration-form/registration-from';
import SEO from '../../../components/seo';

export default function RegisterJAFZA(props) {
  return (
    <Layout>
      <SEO lang='en' title='#GMIS2022 - Confindustria Italy' />
      <RegistrationFrom
        source='confindustria-italy'
        page='confindustria-italy'
        imageName='confindustria-italy'
        imageAlt='confindustria-italy'
      />
    </Layout>
  );
}
